import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import moment from "moment";
import ExcelJS from "exceljs";
import { useCookies } from "react-cookie";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";

import {
  Flex,
  Text,
  Box,
  Heading,
  Icon,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Progress,
  Button,
  Grid,
  SimpleGrid,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

// Custom components
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import Header from "../../components/Header";

function Authority() {
  const textColor = useColorModeValue("gray.700", "white");
  const [cookies, setCookie, removeCookie] = useCookies();
  const [authoritiesList, setAuthoritiesList] = useState([]);
  const [offset, setOffset] = useState(0); //何番目のアイテムから表示するか
  const perPage = 20; //1ページあたりに表示したいアイテムの数

  const handlePageChange = (data) => {
    let page_number = data["selected"]; //クリックした部分のページ数が{selected:2}のような形で戻る
    setOffset(page_number * perPage); //offsetを変更し、表示開始するアイテムの番号を変更
  };

  let navigate = useNavigate();

  if (!cookies.id) {
    navigate("/login");
  }

  useEffect(() => {
    const getAllAuthorities = async () => {
      let logsDataUrl = "";
      if (window.location.hostname == "localhost") {
        logsDataUrl = "http://localhost:3000/v1/mypage/authority/";
      } else if (window.location.hostname == "dev.linough-partner.net") {
        logsDataUrl =
          "https://dev-api.linough-partner.net:3443/v1/mypage/authority/";
      } else {
        logsDataUrl = "https://api.linough-partner.net/v1/mypage/authority/";
      }
      var params = new URLSearchParams();
      params.append("account_id", cookies.id);
      axios.post(logsDataUrl, params).then(function (response) {
        setAuthoritiesList(response.data["response"]["authorities"]);
      });
    };
    getAllAuthorities();
  }, []);

  async function downloadDoc(docType) {
    //ワークブックを作成する
    let workbook = new ExcelJS.Workbook();

    //ワークシートを作成する
    let worksheet = workbook.addWorksheet("sheet1", {});

    worksheet.columns = [
      { header: "建物ID", key: "buildingId" },
      { header: "建物名", key: "name" },
      { header: "都道府県", key: "prefecture" },
      { header: "住所", key: "address" },
      { header: "不動産共通ID", key: "realestateId" },
      { header: "更新日時", key: "updateAt" },
      { header: "登録日時", key: "createdAt" },
    ];

    const authoritiesListBody = authoritiesList.map((authorities) => ({
      buildingId: authorities.building_id,
      name: authorities.property_name,
      prefecture: authorities.prefecture,
      address: authorities.address,
      realestateId: authorities.realestate_id,
      updateAt: moment(authorities.updated_at).format("YYYY年MM月DD日"),
      createdAt: moment(authorities.created_at).format("YYYY年MM月DD日"),
    }));

    worksheet.addRows(authoritiesListBody);

    //エクセルファイルを生成する
    let uint8Array = await workbook.xlsx.writeBuffer(); //xlsxの場合
    if (docType == "csv") {
      uint8Array = await workbook.csv.writeBuffer(); //csvの場合
    }
    let blob = new Blob([uint8Array], { type: "application/octet-binary" });

    //Excelファイルダウンロード
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "ライナフコネクトAPI対象物件リスト." + docType;
    link.click();
  }

  return (
    <>
      <Header />
      <Box
        boxShadow="xs"
        p="6"
        rounded="md"
        bg="white"
        paddingTop={120}
        paddingLeft={50}
        paddingRight={50}
        paddingBottom={70}
      >
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            API対象物件
          </Text>
        </CardHeader>
        {authoritiesList.length === 0 && (
          <p>API許可されている物件は0件です。</p>
        )}
        {authoritiesList.length > 0 && (
          <CardBody>
            <Box marginTop={2} marginBottom={5}>
              <Button marginRight={2} onClick={() => downloadDoc("xlsx")}>
                <DownloadIcon marginRight={1} />
                Excel形式
              </Button>
              <Button onClick={() => downloadDoc("csv")}>
                <DownloadIcon marginRight={1} />
                CSV形式
              </Button>
            </Box>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th color="gray.400">建物ID</Th>
                  <Th color="gray.400">建物名</Th>
                  <Th color="gray.400">都道府県</Th>
                  <Th color="gray.400">住所</Th>
                  <Th color="gray.400">不動産共通ID</Th>
                  <Th color="gray.400">更新日時</Th>
                  <Th color="gray.400">登録日時</Th>
                </Tr>
              </Thead>
              <Tbody>
                {authoritiesList
                  .slice(offset, offset + perPage)
                  .map((authorities) => (
                    <Tr key={authorities.id}>
                      <Td>
                        <Text
                          fontSize="md"
                          color={textColor}
                          fontWeight="bold"
                          pb=".5rem"
                        >
                          {authorities.building_id}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="md"
                          color={textColor}
                          fontWeight="bold"
                          pb=".5rem"
                        >
                          {authorities.property_name}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="md"
                          color={textColor}
                          fontWeight="bold"
                          pb=".5rem"
                        >
                          {authorities.prefecture}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="md"
                          color={textColor}
                          fontWeight="bold"
                          pb=".5rem"
                        >
                          {authorities.address}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="md"
                          color={textColor}
                          fontWeight="bold"
                          pb=".5rem"
                        >
                          {authorities.realestate_id}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="md"
                          color={textColor}
                          fontWeight="bold"
                          pb=".5rem"
                        >
                          {moment(authorities.updated_at).format(
                            "YYYY年MM月DD日"
                          )}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontSize="md"
                          color={textColor}
                          fontWeight="bold"
                          pb=".5rem"
                        >
                          {moment(authorities.created_at).format(
                            "YYYY年MM月DD日"
                          )}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={Math.ceil(authoritiesList.length / perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
              marginPagesDisplayed={5} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
              pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
              onPageChange={handlePageChange} // クリック時のfunction
              containerClassName={"pagination"} // ページネーションであるulに着くクラス名
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} // アクティブなページのliに着くクラス名
              previousClassName={"pagination__previous"} // 「<」のliに着けるクラス名
              nextClassName={"pagination__next"} // 「>」のliに着けるクラス名
              disabledClassName={"pagination__disabled"} // 使用不可の「<,>」に着くクラス名
            />
          </CardBody>
        )}
      </Box>
    </>
  );
}
export default Authority;
