import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import moment from "moment";
import ExcelJS from "exceljs";
import { useCookies } from "react-cookie";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";

import {
  Text,
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
import { DownloadIcon } from '@chakra-ui/icons'

// Custom components
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import Header from "../../components/Header";

import "../../styles/Paging.scss";

function Apilog() {
  const d = new Date();
  const thisYear = d.getFullYear();
  const textColor = useColorModeValue("gray.700", "white");
  const [cookies, setCookie, removeCookie] = useCookies();
  const [logsYear, setLogYear] = useState(d.getFullYear());
  const [logsMonth, setLogMonth] = useState(d.getMonth() + 1);
  const [logsList, setLogList] = useState([]);
  const [offset, setOffset] = useState(0); //何番目のアイテムから表示するか
  const perPage = 20; //1ページあたりに表示したいアイテムの数

  const handlePageChange = (data) => {
    let page_number = data["selected"]; //クリックした部分のページ数が{selected:2}のような形で戻る
    setOffset(page_number * perPage); //offsetを変更し、表示開始するアイテムの番号を変更
  };
  const handleYearChange = (data) => {
    let select_year = data.target.value;
    setLogYear(select_year);
  };
  const handleMonthChange = (data) => {
    let select_month = data.target.value;
    setLogMonth(select_month);
  };

  let navigate = useNavigate();

  if (!cookies.id) {
    navigate("/login");
  }

  useEffect(() => {
    const getAllLogs = async () => {
      let logsDataUrl = "";
      if (window.location.hostname == "localhost") {
        logsDataUrl = "http://localhost:3000/v1/mypage/logs/";
      } else if (window.location.hostname == "dev.linough-partner.net") {
        logsDataUrl =
          "https://dev-api.linough-partner.net:3443/v1/mypage/logs/";
      } else {
        logsDataUrl = "https://api.linough-partner.net/v1/mypage/logs/";
      }
      var params = new URLSearchParams();
      params.append("account_id", cookies.id);
      params.append("year", logsYear);
      params.append("month", logsMonth);
      axios.post(logsDataUrl, params).then(function (response) {
        setLogList(response.data["response"]["logs"]);
      });
    };
    getAllLogs();
  }, [cookies, logsYear, logsMonth]);


  async function downloadDoc(docType){

    //ワークブックを作成する
    let workbook = new ExcelJS.Workbook();
  
    //ワークシートを作成する
    let worksheet = workbook.addWorksheet( "sheet1", {} ) ;

    worksheet.columns = [
      { header: "ID", key: "id" },
      { header: "実行日時", key: "time" },
      { header: "対象建物", key: "property_name" },
      { header: "利用API", key: "endpoint" },
      { header: "実行結果", key: "result" },
      { header: "実行者", key: "action_user_name" },
    ];

    const logsListBody = logsList.map((logs) => (
      {
        id:logs.id,
        time:moment(logs.time).add(9,'h').format('YYYY年MM月DD日 HH:mm:ss'),
        property_name:logs.property_name,
        endpoint:logs.endpoint,
        result:logs.result,
        action_user_name:logs.action_user_name,
      }
    ))

    worksheet.addRows(logsListBody);
   
    //エクセルファイルを生成する
    let uint8Array = await workbook.xlsx.writeBuffer(); //xlsxの場合
    if( docType == 'csv'){
      uint8Array = await workbook.csv.writeBuffer(); //csvの場合
    }
    let blob = new Blob([uint8Array], { type: "application/octet-binary" });
  
    //Excelファイルダウンロード
    let link = document.createElement( "a" );
    link.href = window.URL.createObjectURL( blob );
    link.download = logsYear + "年" + logsMonth + "月ライナフコネクトAPI利用履歴." + docType ;
    link.click();
  }

  return (
    <>
      <Header />
      <Box
        boxShadow="xs"
        p="6"
        rounded="md"
        bg="white"
        paddingTop={120}
        paddingLeft={50}
        paddingRight={50}
        paddingBottom={70}
      >
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            API利用履歴
          </Text>
        </CardHeader>
        <CardBody>
          <Box display="flex" mb={30}>
            <Select
              value={logsYear}
              onChange={handleYearChange}
              w="150px"
              mr="10px"
            >
              <option value={thisYear}>{thisYear}年</option>
              <option value={thisYear-1}>{thisYear-1}年</option>
              <option value={thisYear-2}>{thisYear-2}年</option>
            </Select>
            <Select value={logsMonth} onChange={handleMonthChange} w="150px">
              <option value="12">12月</option>
              <option value="11">11月</option>
              <option value="10">10月</option>
              <option value="9">9月</option>
              <option value="8">8月</option>
              <option value="7">7月</option>
              <option value="6">6月</option>
              <option value="5">5月</option>
              <option value="4">4月</option>
              <option value="3">3月</option>
              <option value="2">2月</option>
              <option value="1">1月</option>
            </Select>
          </Box>
          {logsList.length === 0 && (
            <p>
              {logsYear}年{logsMonth}月の利用履歴は0件です。
            </p>
          )}
          {logsList.length > 0 && (
            <>
            <Box
              marginTop={2}
              marginBottom={5}
            >
            <Button
              marginRight={2}
              onClick={() => downloadDoc("xlsx")}
            >
              <DownloadIcon marginRight={1} />Excel形式
            </Button>
            <Button
              onClick={() => downloadDoc("csv")}
            >
              <DownloadIcon marginRight={1} />CSV形式
            </Button>
            </Box>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th color="gray.400">ID</Th>
                    <Th pl="0px" color="gray.400">
                      実行日時
                    </Th>
                    <Th color="gray.400">対象建物</Th>
                    <Th color="gray.400">利用API</Th>
                    <Th color="gray.400">実行結果</Th>
                    <Th color="gray.400">実行者</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {logsList
                    .slice(offset, offset + perPage) //表示したいアイテムをsliceで抽出
                    .map((logs) => {
                      return (
                        <Tr key={logs.id}>
                          <Td>
                            <Text
                              fontSize="md"
                              color={textColor}
                              fontWeight="bold"
                              pb=".5rem"
                            >
                              {logs.id}
                            </Text>
                          </Td>
                          <Td>
                            <Text
                              fontSize="md"
                              color={textColor}
                              fontWeight="bold"
                              pb=".5rem"
                            >
                              {moment(logs.time).add(9,'h').format(
                                "YYYY年MM月DD日 HH:mm:ss"
                              )}
                            </Text>
                          </Td>
                          <Td>
                            <Text
                              fontSize="md"
                              color={textColor}
                              fontWeight="bold"
                              pb=".5rem"
                            >
                              {logs.property_name}
                            </Text>
                          </Td>
                          <Td>
                            <Text
                              fontSize="md"
                              color={textColor}
                              fontWeight="bold"
                              pb=".5rem"
                            >
                              {logs.endpoint}
                            </Text>
                          </Td>
                          <Td>
                            <Text
                              fontSize="md"
                              color={textColor}
                              fontWeight="bold"
                              pb=".5rem"
                            >
                              {logs.result}
                            </Text>
                          </Td>
                          <Td>
                            <Text
                              fontSize="md"
                              color={textColor}
                              fontWeight="bold"
                              pb=".5rem"
                            >
                              {logs.action_user_name}
                            </Text>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={Math.ceil(logsList.length / perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
                marginPagesDisplayed={5} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
                pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
                onPageChange={handlePageChange} // クリック時のfunction
                containerClassName={"pagination"} // ページネーションであるulに着くクラス名
                subContainerClassName={"pages pagination"}
                activeClassName={"active"} // アクティブなページのliに着くクラス名
                previousClassName={"pagination__previous"} // 「<」のliに着けるクラス名
                nextClassName={"pagination__next"} // 「>」のliに着けるクラス名
                disabledClassName={"pagination__disabled"} // 使用不可の「<,>」に着くクラス名
              />
            </>
          )}
        </CardBody>
      </Box>
    </>
  );
}
export default Apilog;
