import { StrictMode } from "react";
import ReactDOM from "react-dom";

import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from "react-cookie";

import theme from "./theme/theme";

import App from "./components/App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
    <CookiesProvider>
    <ChakraProvider theme={theme} >
      <App />
    </ChakraProvider>
    </CookiesProvider>
    </BrowserRouter>
  </StrictMode>,
  rootElement
);
