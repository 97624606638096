import {
  Routes,
  Route,
} from "react-router-dom";
import Top from '../views/Pages/Top';
import Login from '../views/Pages/Login';
import Apilog from '../views/Pages/Apilog';
import Authority from '../views/Pages/Authority';


function App() {

  return (
    <>
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/login" element={<Login />} />
        <Route path="/apilog" element={<Apilog />} />
        <Route path="/authority" element={<Authority />} />
      </Routes>
    </>
  );
}

export default App;
