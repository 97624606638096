import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles:{
    global:{
      body:{
        padding:"0",
        backgroundColor:"gray.50",
        color:"gray.800",
      },
      p:{
        fontSize: { base:"md",md:"lg"},
        lineHeight:"tall"
      }
    }
  },
  components:{
    Table:{
      baseStyle:{
        table:{
          whiteSpace:"normal"
        },
        th:{
          whiteSpace:"nowrap"
        },
        tr:{
          _last:{
            td:{
              border:"none"
            },
            th:{
              border:"none"
            },
          }
        }
      }
    }
  }
});

export default theme;