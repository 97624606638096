import React , { useState } from 'react';
import { useCookies } from "react-cookie";
import {
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";

import HeaderTop from "../../components/HeaderTop";
import Footer from "../../components/Footer"


function Login(){
    // Chakra color mode
    const textColor = useColorModeValue("gray.400", "white");
    const errorColor = useColorModeValue("red.300", "red.200");
    const [cookies, setCookie, removeCookie] = useCookies();
    const [inputMailaddress,setInputMailaddress] = useState(cookies.mypage_id);
    const [inputPassword,setInputPassword] = useState(cookies.mypage_password);
    const [errorMessage,setErrorMessage] = useState('');
    const handleOnChangeMailaddress = (e) => setInputMailaddress(e.target.value)
    const handleOnChangePassword = (e) => setInputPassword(e.target.value)
    let userId = null;
    let navigate = useNavigate();
    const toast = useToast()

    function checkSignin(){

      let loginCheckUrl = "";
      if( window.location.hostname == "localhost" ){
        loginCheckUrl = "http://localhost:3000/v1/mypage/login/";
      }else if( window.location.hostname == "dev.linough-partner.net" ){
        loginCheckUrl = "https://dev-api.linough-partner.net:3443/v1/mypage/login/";
      }else{
        loginCheckUrl = "https://api.linough-partner.net/v1/mypage/login/";
      }


      var params = new URLSearchParams();
      params.append('mypage_id', inputMailaddress);
      params.append('mypage_password', inputPassword);

      axios.post(loginCheckUrl,params)
        .then(function(response){
          if(response.data['response']['exist'] == true){
            userId = response.data['response']['account']['id'];
            setCookie("mypage_id", inputMailaddress);
            setCookie("mypage_password", inputPassword);
            setCookie("id", userId);
            navigate("/apilog");
          }else{
            toast({
              title:'ログインIDまたはパスワードに誤りがあります。',
              status:'error',
              duration: 3000,
              isClosable:true,
              position:"top-left"
            })
          }
      });
    }

    return (
      <>
      <HeaderTop />
        <Flex
          h={{ sm: "95vh", md: "95vh", lg: "95vh" }}
          w="100%"
          maxW="500px"
          mx="auto"
          justifyContent="space-between"
          mb="30px"
          pt={{ sm: "100px", md: "0px" }}
        >
          <Flex
            alignItems="center"
            justifyContent="start"
            style={{ userSelect: "none" }}
            w={{ base: "100%" , md:"100%" , sm:"100%"  }}
          >
            <Flex
              direction="column"
              w="100%"
              background="transparent"
              p="48px"
              mt={{ md: "150px", lg: "80px" }}
            >
              <Text
                mb="24px"
                fontSize="sm"
                type="text"
                size="lg"
                color={errorColor}
              >
                {errorMessage}
              </Text>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  ログインID
                </FormLabel>
                <Input
                  id="login-id"
                  onChange={handleOnChangeMailaddress}
                  borderRadius="15px"
                  mb="24px"
                  fontSize="sm"
                  type="text"
                  placeholder="ログインID"
                  size="lg"
                  value={inputMailaddress}
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  パスワード
                </FormLabel>
                <Input
                  id="login-password"
                  onChange={handleOnChangePassword}
                  borderRadius="15px"
                  mb="36px"
                  fontSize="sm"
                  type="password"
                  placeholder="パスワード"
                  size="lg"
                  value={inputPassword}
                />
                <Text
                  mb="36px"
                  ms="4px"
                  color={textColor}
                  fontWeight="bold"
                  fontSize="14px"
                >
                  ログインIDとパスワードを入力して「ログイン」ボタンを押してください
                </Text>
                <Button
                  fontSize="sm"
                  type="submit"
                  bg='rgba(0,86,0,1)'
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  _hover={{
                    bg: "rgba(0,86,0,0.8)",
                  }}
                  _active={{
                    bg: "rgba(0,86,0,0.8)",
                  }}
                  onClick={checkSignin}
                >
                  ログイン
                </Button>
              </FormControl>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      <Footer />
    </>
  );
}
export default Login;