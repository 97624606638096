import React, { useState } from "react";

// Chakra imports
import {
  Box,
  Container,
  Button,
  Text,
  Center,
  useBreakpointValue,
} from "@chakra-ui/react";

import "/node_modules/video-react/dist/video-react.css";

import HeaderTop from "../../components/HeaderTop";
import mainvisual from "../../images/img-main.png";

function Top() {
  const toContact = () => {
    window.open("https://lp.linough.com/partnerAPI", "_blank");
  };
  let catchTitle =
    "マンションのエントランスドアを解錠できるAPIを活用してみませんか";
  let catchBody =
    "ライナフコネクトを活用すれば、自社システムにエントランスドア解錠機能を追加して、入居者不在のときでも、お部屋の前まで自社サービスをお届けすることができます。";

    const isMobile = useBreakpointValue({ base: true, md: false })
    const MainVisualButton = () => {
      if (isMobile) {
        return <Center>
        <Button
          size="lg"
          px={20}
          py={7}
          bg="rgba(0,86,0,1)"
          color="#ffffff"
          borderRadius="0px"
          _hover={{ bg: "rgba(0,86,0,0.8)" }}
          onClick={toContact}
        >
          詳しくはこちら
        </Button>
        </Center>
      }
      return <Button
        size="lg"
        px={20}
        py={7}
        bg="rgba(0,86,0,1)"
        color="#ffffff"
        borderRadius="0px"
        _hover={{ bg: "rgba(0,86,0,0.8)" }}
        onClick={toContact}
      >
        詳しくはこちら
      </Button>
    }


  return (
    <>
      <HeaderTop />
      <Box
        style={{ background: `url(${mainvisual}) center/cover no-repeat` }}
        backdropFilter="invert(100%)"
      >
        <Box backgroundColor="rgba(255,255,255,0.3)" pb={150}>
          <Container maxW="1200px" pt="200px">
            <Box width={{md:"600px",sm:"100%"}}>
              <Text fontSize={{md:"4xl",sm:"3xl"}} mb="30px" fontWeight="bold">
                {catchTitle}
              </Text>
              <Text fontSize={{md:"2xl",sm:"xl"}} mb="50px">
                {catchBody}
              </Text>
              <MainVisualButton />
            </Box>
          </Container>
        </Box>
      </Box>

      <Box>
        <Container maxW="1200px" pt={{md:"120px",sm:"50px"}}>
        </Container>
      </Box>

    </>
  );
}
export default Top;
