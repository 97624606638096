import React from "react";
import { useCookies } from "react-cookie";
import {
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import {
  Box,
  Stack,
  Heading,
  Flex,
  Text,
  Button,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";


const Footer = (props) => {

  return (
    <>
    <Box
      bg='rgba(0,86,0,1)'
      pt="10px"
      pb="10px"
    >
      <Text textAlign="center" p={1} color='#FFFFFF'>Linough inc.</Text>
    </Box>
    </>
  );
};

export default Footer;
