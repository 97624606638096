import React from "react";
import { useCookies } from "react-cookie";
import {
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import {
  Box,
  Stack,
  Heading,
  Flex,
  Text,
  Button,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";


const HeaderTop = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleToggle = () => (isOpen ? onClose() : onOpen());
  const cancelRef = React.useRef()
  const [cookies, setCookie, removeCookie] = useCookies();
  let navigate = useNavigate();
  const toast = useToast()

  function login(){
    navigate("/login");
  }

  return (
    <>
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding={6}
      bg='rgba(0,86,0,1)'
      color="white"
      position="fixed"
      zIndex='10'
      width="100%"
      {...props}
    >
      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg" letterSpacing={"tighter"}>
          <a href="/">ライナフコネクト</a>
        </Heading>
      </Flex>

      <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
        <HamburgerIcon />
      </Box>

      <Stack
        direction={{ base: "column", md: "row" }}
        display={{ base: isOpen ? "block" : "none", md: "flex" }}
        width={{ base: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
        mt={{ base: 4, md: 0 }}
      >
      </Stack>

      <Box
        display={{ base: isOpen ? "block" : "none", md: "block" }}
        mt={{ base: 4, md: 0 }}
      >
        <Button
          variant="outline"
          _hover={{ bg: "#FFFFFF", color: "rgba(0,86,0,1)" }}
          onClick={login}
        >
          ログイン
        </Button>
      </Box>
    </Flex>
    </>
  );
};

export default HeaderTop;
